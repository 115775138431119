import {isProxy, toRaw} from 'vue'

export default function ParserUtils() {
  const minWidthAssets = '(min-width: 800px)'

  const sectorsInfo = [
    {label: 'Automotive', path: 'sectors/automotive', slug: 'automotive'},
    {label: 'Architecture', path: 'sectors/architecture', slug: 'architecture'},
    {label: 'Nautical', path: 'sectors/nautical', slug: 'nautical'},
    {
      label: 'Product Design',
      path: 'sectors/product-design',
      slug: 'product',
    },
    {
      label: 'Mobility & Transportation',
      path: 'sectors/mobility-transportation',
      slug: 'mobility',
    },
    {label: 'Wind Tunnel', path: 'sectors/wind-tunnel', slug: 'wind'},
  ]

  // helper function - get cities from country
  const getCities = (data) => {
    const cities = []

    data.forEach((office) => {
      const city = office.city
      cities.push(city)
    })

    return cities
  }

  // helper function - get file type (image, video, document) from url
  const getFileType = (url) => {
    const fileExtension = url.split('.').pop().toLowerCase()

    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension)) {
      return 'image'
    } else if (['mp4', 'avi', 'mkv', 'mov'].includes(fileExtension)) {
      return 'video'
    } else if (['pdf', 'doc', 'docx', 'txt'].includes(fileExtension)) {
      return 'document'
    } else {
      return ''
    }
  }

  // helper function - get internal navigation
  const getInternalNav = (data, relativePath) => {
    const dataObject = isProxy(data) ? toRaw(data) : data
    const nav = []

    dataObject.forEach((item) => {
      const itemObject = {}

      itemObject.label = item.title
      itemObject.path = relativePath + item.slug

      nav.push(itemObject)
    })

    return nav
  }

  // Helper function - get content translations
  const getTranslations = (data, type) => {
    const dataObject = isProxy(data) ? toRaw(data) : data
    const translations = []

    if( dataObject ){
      dataObject.forEach((item) => {
        const itemObject = {}
  
        itemObject.id = item.id
        itemObject.title = item.title
  
        // Estracting last segment of URL for endpoint
        const urlParts = item.url.split('/');
        itemObject.endpoint = urlParts[urlParts.length - 1].replace('.json', '')
        
        // Translations map
        itemObject.translations = {}
        if( item.localized_urls && Array.isArray(item.localized_urls) ){
          item.localized_urls.forEach((translation) => {
            itemObject.translations[translation.locale] = translation.url
          })
        }
  
        translations.push(itemObject)
      })
    }

    return translations
  }

  // helper function - get home hero
  const getHomeHero = (data) => {
    const dataObject = isProxy(data) ? toRaw(data) : data
    const hero = {}

    // intro video
    if (dataObject.files && dataObject.files.intro_video) {
      hero.intro_video = dataObject.files.intro_video.url
    }

    // intro image
    if (
      dataObject.medias &&
      dataObject.medias.intro_image &&
      dataObject.medias.intro_image.url
    ) {
      hero.intro_image = dataObject.medias.intro_image.url.desktop
    }

    hero.menu_items = []

    sectorsInfo.forEach((sector) => {
      const sectorItem = {}
      sectorItem.label = sector.label
      // sectorItem.path = '/' + sector.path
      sectorItem.slug = sector.slug
      sectorItem.assets = []

      if (dataObject.files && dataObject.files[`${sector.slug}_video`]) {
        sectorItem.resource = 'video'
        sectorItem.assets = getVideoAssets(
          dataObject.files[`${sector.slug}_video`].url
        )
      } else if (
        dataObject.medias &&
        dataObject.medias[`${sector.slug}_image`]
      ) {
        sectorItem.resource = 'image'
        sectorItem.assets = getImageAssets(
          dataObject.medias[`${sector.slug}_image`].url
        )
      }
      hero.menu_items.push(sectorItem)
    })

    return hero
  }

  // helper function - generate image assets
  const getImageAssets = (urlObject) => {
    const urlObjectUsable = isProxy(urlObject) ? toRaw(urlObject) : urlObject
    const results = []

    // dekstop
    if (urlObjectUsable.desktop) {
      results.push({
        src: cleanImageUrl(urlObjectUsable.desktop),
        format: getSourceTypeForImages(urlObjectUsable.desktop),
        media: minWidthAssets,
      })
    }

    // mobile
    if (urlObjectUsable.mobile) {
      results.push({
        src: cleanImageUrl(urlObjectUsable.mobile),
        format: getSourceTypeForImages(urlObjectUsable.mobile),
        media: 'image',
      })
    }

    return results
  }

  // helper function - get museum info
  const getMuseumInfo = (timesList) => {
    const list = []

    timesList.forEach((time) => {
      const timeObject = {}

      timeObject.title = time.content.title

      if (!time.content.times) {
        timeObject.text = time.content.text
      } else {
        timeObject.times = []

        time.hours.forEach((hour) => {
          const hourObject = {}

          hourObject.description = hour.content.title
          hourObject.time = hour.content.text

          timeObject.times.push(hourObject)
        })
      }

      list.push(timeObject)
    })

    return list
  }

  // helper function - get offices from country
  const getOffices = (data) => {
    const offices = []

    data.forEach((office) => {
      const officeObject = {}

      officeObject.place = office.city
      officeObject.type = office.description

      officeObject.body = ''

      if (office.address) {
        officeObject.body = officeObject.body + '<p>' + office.address + '</p>'
      }

      if (office.phone) {
        officeObject.body = officeObject.body + '<p>' + office.phone + '</p>'
      }

      if (office.email) {
        officeObject.body = officeObject.body + '<p>' + office.email + '</p>'
      }

      officeObject.links = []

      offices.push(officeObject)
    })

    return offices
  }

  // helper function - get processed paragraphs
  const getParagraphs = (blocks) => {
    try{
      const paragraphs = []

      blocks.forEach((blockProxy) => {
        const block = toRaw(blockProxy)
        const object = {}

        switch (block.type) {
          case 'accordion':
            object.type = 'accordion'
            object.title = block.content.title
            object.accordions = []

            block.tabs.forEach((accordion) => {
              const accordionObject = {}
              accordionObject.title = accordion.content.title
              accordionObject.subtitle = accordion.content.subtitle
              accordionObject.text = accordion.content.text

              if (accordion.content.cta_label) {
                accordionObject.cta = {}
                accordionObject.cta.label = accordion.content.cta_label
                accordionObject.cta.url = accordion.content.cta_url
              }

              if (accordion.medias) {
                accordionObject.assets = getImageAssets(accordion.medias[0].url)
              }

              if (accordion.relations) {
                accordionObject.relations = []

                accordion.relations.forEach((relation) => {
                  const relationObject = {}
                  relationObject.title = relation.title

                  // console.log('DATE (relation.date): ', relation.date)

                  if (relation.date) {
                    relationObject.date = formatDateFull(relation.date)
                  }

                  if (relation.files && relation.files.document)
                    relationObject.file = relation.files.document.url
                  else {
                    relationObject.file = 'default.pdf'
                  }

                  accordionObject.relations.push(relationObject)
                })
              }

              object.accordions.push(accordionObject)
            })

            break

          case 'areas':
            object.type = 'areas'
            object.title = block.content.title
            object.areas = []

            if (block.medias) {
              object.assets = getImageAssets(block.medias[0].url)
            }

            block.areas.forEach((area) => {
              const areaObject = {}
              areaObject.label = area.content.label
              areaObject.url = area.content.url

              if (area.medias) {
                areaObject.assets = getImageAssets(area.medias[0].url)
              }

              object.areas.push(areaObject)
            })

            break

          case 'awards':
            object.type = 'awards'
            object.tabs = []

            block.content.forEach((yearBlock) => {
              const tabObject = {}

              tabObject.year = yearBlock.year
              tabObject.awards = []

              yearBlock.awards.forEach((award) => {
                const awardObject = {}

                awardObject.company = award.company
                awardObject.name = award.title
                awardObject.description = award.description
                awardObject.company = award.company

                if (award.project && award.project.slug)
                  awardObject.project_url = '/projects/' + award.project.slug

                if (award.medias && award.medias.cover) {
                  awardObject.logo_assets = getImageAssets(award.medias.cover.url)
                }

                if (award.medias && award.medias.hover) {
                  awardObject.hover_assets = getImageAssets(award.medias.hover.url)
                }

                tabObject.awards.push(awardObject)
              })

              object.tabs.push(tabObject)
            })

            object.tabs.reverse()

            break

          case 'banner':
            object.type = 'banner'
            object.layout = block.content.layout
            object.list = []

            block.banners.forEach((banner) => {
              const bannerObject = {}
              bannerObject.title = banner.content.title
              bannerObject.text = banner.content.text

              if (banner.content.cta_label) {
                bannerObject.cta = {}
                bannerObject.cta.label = banner.content.cta_label
                bannerObject.cta.url = banner.content.cta_url
              }

              if (banner.medias && banner.medias[0]) {
                bannerObject.assets = getImageAssets(banner.medias[0].url)
              }

              if (banner.content.modal == 1) {
                bannerObject.modal = true
              } else {
                bannerObject.modal = false
              }

              object.list.push(bannerObject)
            })

            break

          case 'carreers':
            object.type = 'careers'
            object.locations_list = []
            object.jobs_list = []

            // altamira
            if (block.content.altamira) {
              block.content.altamira.forEach((job) => {
                const jobObject = {}

                jobObject.id = job.id
                jobObject.title = job.title
                jobObject.link = job.url
                jobObject.location = job.location
                jobObject.remote = job.remote
                jobObject.origin = 'altamira'

                const datePublished = new Date(job.published)
                jobObject.date = datePublished

                const dateCurrent = new Date()
                const differenceInDays =
                  (dateCurrent - datePublished) / (1000 * 60 * 60 * 24)
                jobObject.new = differenceInDays < 15

                // check if the location is already in locations_list
                const locationExists = object.locations_list.some(function (
                  location
                ) {
                  return location.value === job.location
                })

                // if the location is not in locations_list, add it
                if (!locationExists) {
                  var newLocationObject = {
                    value: job.location,
                    label: job.location,
                  }

                  object.locations_list.push(newLocationObject)
                }

                object.jobs_list.push(jobObject)
              })
            }

            // cms
            if (block.content.cms) {
              block.content.cms.forEach((job) => {
                if (!job || Array.isArray(job)) {
                  return
                }

                const jobObject = {}

                jobObject.id = job.id
                jobObject.title = job.title
                jobObject.link = job.slug
                jobObject.origin = 'cms'
                jobObject.remote = job.remote

                if (job.office) {
                  jobObject.location = job.office.title
                }

                // check data new
                const datePublished = new Date(job.publish_start_date)
                jobObject.date = datePublished

                const dateCurrent = new Date()
                const differenceInDays =
                  (dateCurrent - datePublished) / (1000 * 60 * 60 * 24)
                jobObject.new = differenceInDays < 15

                // check if the location is already in locations_list
                const locationExists = object.locations_list.some(function (
                  location
                ) {
                  return location.value === jobObject.location
                })

                // if the location is not in locations_list, add it
                if (!locationExists && jobObject.location) {
                  var newLocationObject = {
                    value: jobObject.location,
                    label: jobObject.location,
                  }

                  object.locations_list.push(newLocationObject)
                }

                object.jobs_list.push(jobObject)
              })
            }

            // sort locations list
            object.locations_list.sort((a, b) => a.label.localeCompare(b.label))

            // sort jobs list
            // object.jobs_list.sort((a, b) => a.title.localeCompare(b.title))
            object.jobs_list.sort((a, b) => b.date - a.date)

            break

          case 'certifications':
            object.type = 'certifications'
            object.tabs = []

            block.content.forEach((categoryBlock) => {
              const tabObject = {}

              tabObject.category = categoryBlock.category
              tabObject.documents = []

              categoryBlock.certifications.forEach((document) => {
                const documentObject = {}

                documentObject.title = document.title
                documentObject.description = document.description
                documentObject.date = formatDate(document.date)

                if (document.files && document.files.document) {
                  documentObject.file = document.files.document.url
                }

                tabObject.documents.push(documentObject)
              })

              object.tabs.push(tabObject)
            })

            break

          case 'contacts':
            object.type = 'contacts_more'
            object.list = []

            block.contacts.forEach((contact) => {
              const contactObject = {}

              contactObject.title = contact.content.title
              contactObject.name = contact.content.name
              contactObject.role = contact.content.role
              contactObject.body = contact.content.text

              object.list.push(contactObject)
            })

            break

          case 'ctas-big':
            object.type = 'ctas_big'

            object.layout = '1'
            if (block.content && block.content.layout)
              object.layout = block.content.layout

            object.ctas = []

            block.ctas.forEach((cta) => {
              const ctaObject = {}

              ctaObject.label = cta.content.cta_label
              ctaObject.url = cta.content.cta_url

              object.ctas.push(ctaObject)
            })

            break

          case 'downloads':
            object.type = 'downloads'
            object.download_list = []
            object.geo_visibility = block.content.geo_visibility

            block.files.forEach((file) => {
              const downloadObject = {}

              downloadObject.title = file.content.title
              downloadObject.file = file.content.url

              if (file.content.date)
                downloadObject.date = formatDate(file.content.date)

              object.download_list.push(downloadObject)
            })

            break

          case 'embed-video':
            object.type = 'embedded_video'
            object.url = block.content.url

            if (block.medias && block.medias[0]) {
              object.placeholder = block.medias[0].url.desktop
            }

            break

          case 'gallery':
            object.type = 'gallery'
            object.images = []

            if (block.medias) {
              block.medias.forEach((media) => {
                const mediaObject = {}

                mediaObject.alternative = media.alt_text
                mediaObject.assets = getImageAssets(media.url)

                object.images.push(mediaObject)
              })
            }

            break

          case 'gallery-multiple-description':
            if (block.content.type == 'single') {
              object.type = 'gallery_with_description_single'
            } else if (block.content.type == 'normal') {
              object.type = 'gallery_with_description_multiple'
            }

            object.slides = []

            block['gallery-slides'].forEach((slide) => {
              const slideObject = {}
              slideObject.label = slide.content.label
              slideObject.title = slide.content.title
              slideObject.text = slide.content.text

              if (slide.content.cta_label) {
                slideObject.cta = {}
                slideObject.cta.label = slide.content.cta_label
                slideObject.cta.url = slide.content.cta_url
              }

              if (slide && slide.medias && slide.medias[0]) {
                slideObject.assets = getImageAssets(slide.medias[0].url)
              }

              object.slides.push(slideObject)
            })

            break

          case 'gallery-text':
            object.type = 'gallery_full_text'
            object.slides = []

            block['text-slides'].forEach((slide) => {
              const slideObject = {}
              slideObject.title = slide.content.title
              slideObject.description = slide.content.description

              if (slide.medias && slide.medias[0]) {
                slideObject.assets = getImageAssets(slide.medias[0].url)
              }

              object.slides.push(slideObject)
            })

            break

          case 'hours':
            object.type = 'info_museum'

            object.collection = {}
            object.collection.title = block.content.right_title
            object.collection.text = block.content.right_text

            object.info = {}
            object.info.title = block.content.left_title

            object.info.general = getMuseumInfo(block.times)

            break

          case 'image-title-text-cta':
            object.type = 'image_title_text_cta'
            object.title = block.content.title
            object.text = block.content.text

            if (block.content.cta_label) {
              object.cta = {}
              object.cta.label = block.content.cta_label
              object.cta.url = block.content.cta_url
            }

            if (block.medias) {
              object.assets = getImageAssets(block.medias[0].url)
            }

            break

          case 'intro':
            object.type = 'intro'
            object.title = block.content.title
            object.text = block.content.text
            object.layout = block.content.layout ? block.content.layout : 1

            if (block.content.cta_label) {
              object.cta = {}
              object.cta.label = block.content.cta_label
              object.cta.url = block.content.cta_url
            }

            if (block.medias && block.medias[0]) {
              object.image1 = {}
              object.image1.alternative = block.medias[0].alt_text
              object.image1.assets = getImageAssets(block.medias[0].url)
            }

            if (block.medias && block.medias[1]) {
              object.image2 = {}
              object.image2.alternative = block.medias[1].alt_text
              object.image2.assets = getImageAssets(block.medias[1].url)
            }

            if (block.medias && block.medias[2]) {
              object.image3 = {}
              object.image3.alternative = block.medias[2].alt_text
              object.image3.assets = getImageAssets(block.medias[2].url)
            }

            break

          case 'map':
            object.type = 'map'
            break

          case 'mediahub':
            object.type = 'mediahub'
            object.public = []
            block.content.public.forEach((resource) => {
              const resourceObject = {}

              resourceObject.date = formatDate(resource.publish_start_date)
              resourceObject.title = resource.title
              resourceObject.year = resource.year

              if (resource.category) {
                resourceObject.category_id = resource.category.id
                resourceObject.context = resource.category.title
              }

              if (resource.sectors) {
                resourceObject.sector = {}
                resourceObject.sector.id = resource.sectors[0]['id']
                resourceObject.sector.title = resource.sectors[0]['title']
              }

              if (
                resource.files &&
                resource.files.file &&
                resource.files.file.url
              )
                resourceObject.type = getFileType(resource.files.file.url)

              if (resource.files && resource.files.file)
                resourceObject.size = resource.files.file.size

              if (
                resource.files &&
                resource.files.file &&
                resource.files.file.url
              )
                resourceObject.download = resource.files.file.url

              if (resource.medias && resource.medias.image) {
                resourceObject.preview_assets = getImageAssets(
                  resource.medias.image.url
                )
              }

              object.public.push(resourceObject)
            })

            object.private = []

            break

          case 'news':
            object.type = 'news_' + block.content.layout
            object.title = block.content.title
            object.list = []
            block.news.forEach((news) => {
              if (!news || Array.isArray(news)) {
                return
              }

              const newsObject = {}
              newsObject.id = news.id
              newsObject.title = news.title
              newsObject.slug = news.slug
              newsObject.date = formatDateNotime(news.date)

              if (news.medias && news.medias.cover) {
                newsObject.assets = getImageAssets(news.medias.cover.url)
              } else {
                newsObject.assets = [
                  {
                    src: '/placeholders/placeholder.jpg',
                    format: 'image/jpeg',
                    media: 'image',
                  },
                ]
              }

              object.list.push(newsObject)
            })

            object.list.sort((a, b) => new Date(b.date) - new Date(a.date))

            break

          case 'news-list':
            object.type = 'news_list'
            object.list = []

            block.content.forEach((news) => {
              if (!news || Array.isArray(news)) {
                return
              }

              const newsObject = {}

              newsObject.id = news.id
              newsObject.title = news.title
              newsObject.slug = news.slug
              newsObject.date = formatDateNotime(news.date)

              if (news.medias && news.medias.cover) {
                newsObject.assets = getImageAssets(news.medias.cover.url)
              } else {
                newsObject.assets = [
                  {
                    src: '/placeholders/placeholder.jpg',
                    format: 'image/jpeg',
                    media: 'image',
                  },
                ]
              }

              object.list.push(newsObject)
            })

            object.list.sort((a, b) => new Date(b.date) - new Date(a.date))

            break

          case 'newsletter-archive':
            object.type = 'newsletter_archive'

            object.title = block.content.title
            object.accordion = []

            block.newsletters.forEach((accordion) => {
              const accordionObject = {}

              accordionObject.title = accordion.content.title
              accordionObject.list = []

              accordion.files.forEach((file) => {
                const fileObject = {}

                fileObject.title = file.content.title
                if (file.content.date) {
                  fileObject.date = formatDate(file.content.date)
                }

                fileObject.file = file.content.url

                accordionObject.list.push(fileObject)
              })

              object.accordion.push(accordionObject)
            })

            break

          case 'offices':
            object.type = 'offices_home'
            object.title = block.content.title
            object.countries = []

            if (block.content.cta_label) {
              object.cta = {}
              object.cta.label = block.content.cta_label
              object.cta.url = '/contacts/our-offices'
            }

            block.offices.forEach((office) => {
              if (!office || Array.isArray(office)) {
                return
              }

              const officeObject = {}

              officeObject.name = office.country

              if (office.country.toLowerCase() === 'italy') {
                officeObject.name = officeObject.name + '/HQ'
              }

              officeObject.url = '/contacts/' + office.country.toLowerCase()

              officeObject.cities = office.cities

              if (office.medias && office.medias[0]) {
                officeObject.assets = getImageAssets(office.medias[0].url)
              }

              object.countries.push(officeObject)
            })

            break

          case 'offices-list':
            object.type = 'offices_list'
            object.countries = []

            block.content.forEach((country) => {
              if (!country || Array.isArray(country)) {
                return
              }
              const countryObject = {}

              countryObject.label = country.country
              countryObject.path = '/contacts/' + country.country
              countryObject.offices = []

              country.offices.forEach((office) => {
                if (!office || Array.isArray(office)) {
                  return
                }

                const officeObject = {}

                officeObject.place = office.city
                officeObject.type = office.description

                officeObject.body = ''

                if (office.address) {
                  officeObject.body += '<p>' + office.address + '</p>'
                }

                if (office.phone || office.email) {
                  officeObject.body += '<p>'

                  if (office.phone) {
                    officeObject.body += office.phone
                  }

                  if (office.phone && office.email) {
                    officeObject.body += '<br>'
                  }

                  if (office.email) {
                    officeObject.body += office.email
                  }

                  officeObject.body += '</p>'
                }

                countryObject.offices.push(officeObject)
              })

              object.countries.push(countryObject)
            })

            break

          case 'partners':
            object.type = 'partners'
            object.title = block.content.title
            object.list = []

            if (block.partners) {
              block.partners.forEach((partner) => {
                const partnerObject = {}

                if (partner.medias && partner.medias[0]) {
                  partnerObject.name = partner.medias[0].alt_text
                  partnerObject.image = partner.medias[0].url.desktop
                }

                object.list.push(partnerObject)
              })
            }

            break

          case 'pillars':
            object.type = 'list_box'
            object.title = block.content.title
            object.list = []

            block.pillars.forEach((pillar) => {
              const objectPillar = {}

              objectPillar.title = pillar.content.title
              objectPillar.text = pillar.content.text

              object.list.push(objectPillar)
            })

            break

          case 'pretitle-title-text':
            object.type = 'pretitle_title_text'
            object.pretitle = block.content.pretitle
            object.title = block.content.title
            object.text = block.content.text

            break

          case 'pretitle-title-text-gallery':
            object.type = 'pretitle_title_text_and_gallery'
            object.geo_visibility = block.content.geo_visibility 
            object.pretitle = block.content.pretitle
            object.title = block.content.title
            object.text = block.content.text
            object.images = []

            if (block.medias) {
              block.medias.forEach((media) => {
                const mediaObject = {}

                mediaObject.alternative = media.alt_text
                mediaObject.assets = getImageAssets(media.url)

                object.images.push(mediaObject)
              })
            }

            break

          case 'projects':
            object.type = 'projects'
            object.layout = block.content.layout
            object.title = block.content.title
            object.list = []

            if (block.content.cta_label) {
              object.cta = {}
              object.cta.label = block.content.cta_label
              object.cta.url = block.content.cta_url
            }

            if (block.projects) {
              block.projects.forEach((project) => {
                if (!project || Array.isArray(project)) {
                  return
                }

                const projectObject = {}

                projectObject.id = project.id

                if (project.title) projectObject.title = project.title

                if (project.slug) projectObject.slug = project.slug

                projectObject.category = []
                if (project.categories) {
                  project.categories.forEach((category) => {
                    projectObject.category.push(category.title)
                  })
                }

                // awards
                if (
                  project.awarded ||
                  (project.awards && project.awards.length > 0)
                ) {
                  projectObject.awards = []

                  project.awards.forEach((award) => {
                    const awardObject = {}

                    if (
                      award.medias &&
                      award.medias.cover &&
                      award.medias.cover.url
                    ) {
                      awardObject.logo_assets = getImageAssets(
                        award.medias.cover.url
                      )
                    }

                    projectObject.awards.push(awardObject)
                  })
                }

                if (project.medias.preview) {
                  projectObject.assets = getImageAssets(
                    project.medias.preview.url
                  )
                } else {
                  projectObject.assets = [
                    {
                      src: '/placeholders/placeholder.jpg',
                      format: 'image/jpeg',
                      media: 'image',
                    },
                  ]
                }

                object.list.push(projectObject)
              })
            }

            break

          case 'relations':
            object.type = 'investor_relations'
            object.tabs = []

            block.content.forEach((tab) => {
              const tabObject = {}

              tabObject.type = 'regular'
              tabObject.title = tab.category
              tabObject.accordion = []

              tab.years.forEach((year) => {
                const accordionObject = {}

                accordionObject.title = year.year
                accordionObject.list = []

                year.relations.forEach((relation) => {
                  const relationObject = {}

                  relationObject.title = relation.title

                  if (relation.date) {
                    relationObject.date = formatDate(relation.date)
                  }

                  if (relation.files.document)
                    relationObject.file = relation.files.document.url

                  accordionObject.list.push(relationObject)
                })

                tabObject.accordion.push(accordionObject)
              })

              object.tabs.push(tabObject)
            })

            break

          case 'screamer-text':
            object.type = 'screamer_text'
            object.screamer = block.content.screamer
            object.text = block.content.text
            object.author = {}
            object.author.name = block.content.name
            object.author.role = block.content.role

            break

          case 'sectors':
            object.type = 'sectors_about'
            object.title = block.content.title
            object.subtitle = block.content.subtitle

            object.list = []

            block.sectors.forEach((sectorInfo) => {
              const sectorObject = {}
              sectorObject.label = sectorInfo.title
              sectorObject.path = '/sectors/' + sectorInfo.slug
              object.list.push(sectorObject)
            })

            break

          case 'sectors-home':
            object.type = 'sectors_home'
            object.title = block.content.title

            object.sectors = []

            sectorsInfo.forEach((sectorInfo) => {
              // console.log('sectorInfo, ', sectorInfo)
              const sectorObject = {}
              sectorObject.label = block.content[`title_${sectorInfo.slug}`]
              sectorObject.text = block.content[`text_${sectorInfo.slug}`]
              sectorObject.slug = sectorInfo.slug
              sectorObject.cta = {
                label: block.content[`cta_label_${sectorInfo.slug}`],
                // path: `/${sectorInfo.path}`,
              }
              object.sectors.push(sectorObject)
            })

            break

          case 'tabs':
            object.type = 'tabs'
            object.title = block.content.title
            object.subtitle = block.content.subtitle
            object.text = block.content.text
            object.tabs = []

            if (block.content.cta_label) {
              object.cta = {}
              object.cta.label = block.content.cta_label
              object.cta.url = block.content.cta_url
            }

            block.tabs.forEach((tab) => {
              const tabObject = {}
              tabObject.title = tab.content.title
              tabObject.subtitle = tab.content.subtitle
              tabObject.text = tab.content.text
              tabObject.video = {}
              tabObject.video.url = tab.content.video_url

              if (tab.medias) {
                tabObject.video.medias = getImageAssets(tab.medias[0].url)
              }

              if (tab.content.cta_label) {
                tabObject.cta = {}
                tabObject.cta.label = tab.content.cta_label
                tabObject.cta.url = tab.content.cta_url
              }

              object.tabs.push(tabObject)
            })

            break

          case 'team':
            object.type = 'team_' + block.content.layout
            object.list = []

            if (
              block.content &&
              block.content.groups &&
              block.content.groups[0]
            ) {
              object.title = block.content.groups[0].country
              block.content.groups[0].people.forEach((listElem) => {
                if (!listElem || Array.isArray(listElem)) {
                  return
                }

                const listObject = {}

                listObject.name = listElem.name
                listObject.role = listElem.role
                listObject.bio = listElem.bio
                listObject.linkedin = listElem.linkedin

                if (listElem.medias && listElem.medias.photo)
                  listObject.assets = getImageAssets(listElem.medias.photo.url)

                object.list.push(listObject)
              })
            }

            break

          case 'text':
            object.type = 'text'
            object.text = block.content.text

            break

          case 'title-text':
            object.type = 'title_text'
            object.title = block.content.title
            object.text = block.content.text

            break

          case 'title-text-cta':
            object.type = 'title_text_cta'
            object.title = block.content.title
            object.text = block.content.text
            object.cta = {}
            object.cta.label = block.content.cta_label
            object.cta.url = block.content.cta_url

            break

          case 'title-text-cta-number':
            object.type = 'title_text_cta_number'
            object.list = []

            block['tab-numbers'].forEach((number) => {
              const numberObject = {}

              numberObject.title = number.content.title
              numberObject.text = number.content.text
              numberObject.number = number.content.number

              if (number.content.cta_label) {
                numberObject.cta = {}
                numberObject.cta.label = number.content.cta_label
                numberObject.cta.url = number.content.cta_url
              }

              object.list.push(numberObject)
            })

            break

          case 'title-text-and-gallery-with-description':
            object.type = 'title_text_and_gallery_with_description'
            object.title = block.content.title
            object.text = block.content.text
            object.slides = []

            block['text-description-slides'].forEach((slide) => {
              const slideObject = {}

              slideObject.title = slide.content.title
              slideObject.description = slide.content.description

              slideObject.assets = getImageAssets(slide.medias[0].url)

              object.slides.push(slideObject)
            })

            break

          default:
            break
        }

        paragraphs.push(object)
      })

      return paragraphs
    }catch(error){
      console.error('Error:', error);
    }
  }

  // helper function - get projects list for portfolio
  const getProjectsList = (data) => {
    const list = []

    data.forEach((project) => {
      const projectObject = {}

      projectObject.id = project.id

      if (project.title) projectObject.title = project.title

      if (project.slug) projectObject.slug = project.slug

      projectObject.category = []
      if (project.categories) {
        project.categories.forEach((category) => {
          let category_array = {
            title: category.title,
            translation: category.translation,
          }
          projectObject.category.push(category_array)
        })
      }

      if (project.medias.preview) {
        projectObject.assets = getImageAssets(project.medias.preview.url)
      } else {
        projectObject.assets = [
          {
            src: '/placeholders/placeholder.jpg',
            format: 'image/jpeg',
            media: 'image',
          },
        ]
      }

      // awards
      if (project.awarded || (project.awards && project.awards.length > 0)) {
        projectObject.awarded = true
        projectObject.awards = []

        project.awards.forEach((award) => {
          const awardObject = {}

          if (award.medias && award.medias.cover && award.medias.cover.url) {
            awardObject.logo_assets = getImageAssets(award.medias.cover.url)
          }

          projectObject.awards.push(awardObject)
        })
      }

      list.push(projectObject)
    })

    return list.reverse()
  }

  // helper function - get sector categories for portfolio
  const getSectorCategories = (data) => {
    const categoryMap = new Map()

    // Iterate over the JSON data and add unique category titles to the Map
    data.forEach((item) => {
      if (item.categories) {
        item.categories.forEach((category) => {
          if (!categoryMap.has(category.title)) {
            categoryMap.set(category.title, {
              title: category.title,
              translation: category.translation,
            })
          }
        })
      }
    })

    // Convert the Map values to an array
    const categoryList = Array.from(categoryMap.values())

    return categoryList
  }

  // helper function - get assets
  const getSeoData = (data) => {
    const dataObjectUsable = isProxy(data) ? toRaw(data) : data
    const seo = {}

    seo.title = dataObjectUsable.seo_title
    seo.description = dataObjectUsable.seo_description
    seo.image = null

    if (dataObjectUsable.medias && dataObjectUsable.medias.seo_image) {
      seo.image = dataObjectUsable.medias.seo_image.url.desktop
    }

    return seo
  }

  // helper function - identify image format
  const getSourceTypeForImages = (imageUrl) => {
    const urlObject = new URL(imageUrl)
    const fmParam = urlObject.searchParams.get('fm')

    let extension = null

    if (fmParam) {
      extension = fmParam
    } else {
      extension = imageUrl.split('.').pop().toLowerCase().split('?')[0]
    }

    if (extension === 'jpg' || extension === 'jpeg') {
      return 'image/jpg'
    } else if (extension === 'png') {
      return 'image/png'
    } else if (extension === 'webp') {
      return 'image/webp'
    } else {
      return ''
    }
  }

  // helper function - identify video format
  const getSourceTypeForVideo = (videoUrl) => {
    const urlObject = new URL(videoUrl)
    const formatParam = urlObject.searchParams.get('format')

    let extension = null

    if (formatParam) {
      extension = formatParam
    } else {
      const pathSegments = urlObject.pathname.split('/')
      const fileName = pathSegments[pathSegments.length - 1]
      extension = fileName.split('.').pop().toLowerCase()
    }

    switch (extension) {
      case 'mp4':
        return 'video/mp4'
      case 'webm':
        return 'video/webm'
      case 'ogg':
        return 'video/ogg'
      case 'mkv':
        return 'video/x-matroska'
      default:
        return ''
    }
  }

  // helper function - generate video assets
  const getVideoAssets = (urlObject) => {
    const urlObjectUsable = isProxy(urlObject) ? toRaw(urlObject) : urlObject
    const results = []

    results.push({
      src: urlObjectUsable,
      format: getSourceTypeForVideo(urlObjectUsable),
    })

    return results
  }

  // GENERAL NAVIGATION PARSER
  const generalInternalNavigation = (data, label) => {
    const result = {}

    // general info
    result.label = label
    result.menu_items = getInternalNav(data, '/investor-relations/')

    return result
  }

  // GENERAL PAGE PARSER (methodology, classiche, etc..)
  const generalPage = (data) => {
    const result = {}
    result.section = {}

    // general info
    result.section.id = data.id
    result.section.title = data.title
    result.section.description = data.description

    // intro image assets
    if( data.medias.intro_image ){
      result.section.assets = getImageAssets(data.medias.intro_image.url)
    }

    // video asset
    if( data.files && data.files.video && data.files.video.url) {
      result.section.video = {}
      result.section.video.url = data.files.video.url
      result.section.video.type = getSourceTypeForVideo(data.files.video.url)
    } else if (
      data.files &&
      data.files.intro_video &&
      data.files.intro_video.url
    ) {
      result.section.video = {}
      result.section.video.url = data.files.intro_video.url
      result.section.video.type = getSourceTypeForVideo(
        data.files.intro_video.url
      )
    }

    // sensible data for investor relations
    if (data.sensible_data) {
      result.section.sensible_data = true
    } else {
      result.section.sensible_data = false
    }

    // seo info
    result.section.seo = getSeoData(data)

    // paragraphs
    result.section.paragraphs = getParagraphs(data.blocks)

    return result
  }

  // COUNTRY PAGE PARSER
  const countryPage = (data) => {
    const result = {}
    result.section = {}

    // general info
    result.section.id = data.id
    result.section.title = data.title
    result.section.description = data.description

    // seo info
    result.section.seo = getSeoData(data)

    // cover assets back
    if (data.medias && data.medias.cover_bg) {
      result.section.assets_back = getImageAssets(data.medias.cover_bg.url)
    }

    // cover assets front
    if (data.medias && data.medias.cover_fg) {
      result.section.assets_front = getImageAssets(data.medias.cover_fg.url)
    }

    // get city list
    result.section.cities = getCities(data.offices)

    // get offices list
    result.section.offices = getOffices(data.offices)

    // social links
    result.section.social = {}
    result.section.social.linkedin = data.linkedin_url
    result.section.social.twitter = data.twitter_url
    result.section.social.facebook = data.facebook_url
    result.section.social.instagram = data.instagram_url

    // paragraphs
    result.section.paragraphs = getParagraphs(data.blocks)

    result.section.office_list_select = [
      {
        value: 'shanghai',
        label: 'Shanghai office',
      },
      {
        value: 'beijin',
        label: 'Beijin',
      },
    ]

    result.section.interestedin_list_select = [
      {
        value: 'general',
        label: 'General information',
      },
      {
        value: 'automotive',
        label: 'Automotive',
      },
      {
        value: 'architecture',
        label: 'Architecture',
      },
      {
        value: 'nautical',
        label: 'Nautical',
      },
      {
        value: 'mobility',
        label: 'Mobility',
      },
    ]

    return result
  }

  // PARDOT EMAIL SETTINGS PARSER
  const emailSettings = (data) => {
    const results = {}

    data['notifications'].forEach((notification) => {
      let vertical = notification.vertical
      let legal =
        notification.legal_entity !== null
          ? notification.legal_entity
          : 'no_legal'

      if (!results[vertical]) {
        results[vertical] = {}
      }

      results[vertical][legal] = notification.email
    })

    return results
  }

  // JOB PAGE PARSER (career datail)
  const jobPage = (data) => {
    const result = {}
    result.section = {}

    // general info
    result.section.id = data.id
    result.section.title = data.title
    result.section.description = data.description

    // seo info
    result.section.seo = getSeoData(data)

    // checking external ADP
    if( data.is_adp_external && data.adp_external_url ){
      result.section.adp_external_url = data.adp_external_url
    }

    // job info
    if (data.office) {
      result.section.location = data.office.title
    }
    result.section.remote = data.remote
    result.section.date = formatDateFull(data.publish_start_date)
    result.section.content = data.description

    return result
  }

  // HOMEPAGE PARSER
  const homePage = (data) => {
    const result = {}
    result.section = {}

    // general info
    result.section.id = data.type
    result.section.title = data.title
    result.section.description = data.description

    // seo info
    result.section.seo = getSeoData(data)

    // sectors hero info
    result.section.hero = getHomeHero(data)

    // paragraphs
    result.section.paragraphs = getParagraphs(data.blocks)

    return result
  }

  // MEDIA HUB PUBLIC PARSER
  // const mediahubPublicPage = (data) => {

  // }

  // NEWS DETAIL PAGE PARSER
  const newsPage = (data) => {
    const result = {}
    result.section = {}
    result.section.related = {};

    // general info
    result.section.id = data.id
    result.section.title = data.title
    result.section.description = data.description
    result.section.slug = data.slug
    if (data.category) result.section.category = data.category.title
    result.section.date = formatDate(data.publish_start_date)

    if (data.project) {
      result.section.related.project = {
        id: data.project.id,
        slug: data.project.slug,
        title: data.project.title,
        assets: getImageAssets(data.project.medias.preview.url),
      }
    }

    if (data.service) {
      result.section.related.service = {
        id: data.service.id,
        slug: data.service.slug,
        title: data.service.title,
      }
    }

    // seo info
    result.section.seo = getSeoData(data)

    // cover assets
    if (data.medias.cover) {
      result.section.assets = getImageAssets(data.medias.cover.url)
    }

    // paragraphs
    result.section.paragraphs = getParagraphs(data.blocks)

    // extra
    result.section.extra = getParagraphs(data.extra)

    return result
  }

  // NEWS LISTING PAGE PARSER
  const newsListingPage = (data) => {
    const result = {}
    result.section = {}

    // general info
    result.section.id = data.id
    result.section.title = data.title
    result.section.description = data.description

    // seo info
    result.section.seo = getSeoData(data)

    // paragraphs
    result.section.paragraphs = getParagraphs(data.blocks)

    return result
  }

  // PORTFOLIO SECTOR LISTING PAGE PARSER
  const portfolioSectorPage = (data) => {
    const result = {}
    result.section = {}

    // general info
    result.section.id = data.id
    result.section.title = data.title
    result.section.description = data.description

    // seo info
    result.section.seo = getSeoData(data)

    result.section.projects_archive = {}

    // generate category list
    result.section.projects_archive.category_list = getSectorCategories(
      data.projects
    )

    // projects
    result.section.projects_archive.list = getProjectsList(data.projects)

    return result
  }

  // PROJECT DETAIL PAGE PARSER
  const projectPage = (data) => {
    const result = {}
    result.section = {}

    // general info
    result.section.id = data.type
    result.section.title = data.title
    result.section.description = data.description
    result.section.slug = data.slug

    if (data.categories) result.section.category = data.category.title

    result.section.category = []
    if (data.categories) {
      data.categories.forEach((category) => {
        result.section.category.push(category.title)
      })
    }

    result.section.client = data.client
    result.section.location = data.location
    result.section.year = data.year
    result.section.abstract = data.abstract

    // awards
    if (data.awarded || (data.awards && data.awards.length > 0)) {
      result.section.awards = []

      data.awards.forEach((award) => {
        const awardObject = {}

        if (award.medias && award.medias.cover && award.medias.cover.url) {
          awardObject.logo_assets = getImageAssets(award.medias.cover.url)
        }

        result.section.awards.push(awardObject)
      })
    }

    // seo info
    result.section.seo = getSeoData(data)

    // cover assets
    if (data.medias.cover) {
      result.section.assets = getImageAssets(data.medias.cover.url)
    }

    // paragraphs
    result.section.paragraphs = getParagraphs(data.blocks)

    return result
  }

  // SECTOR/SERVICE PAGE PARSER
  const sectorPage = (data) => {
    const result = {}
    result.section = {}

    // general info
    result.section.id = data.type
    result.section.title = data.title
    result.section.description = data.description

    // seo info
    result.section.seo = getSeoData(data)

    // cover assets
    if (data.medias.cover) {
      result.section.assets = getImageAssets(data.medias.cover.url)
    }

    // video asset
    if (data.files && data.files.video && data.files.video.url) {
      result.section.video = {}
      result.section.video.url = data.files.video.url
      result.section.video.type = getSourceTypeForVideo(data.files.video.url)
    } else if (
      data.files &&
      data.files.intro_video &&
      data.files.intro_video.url
    ) {
      result.section.video = {}
      result.section.video.url = data.files.intro_video.url
      result.section.video.type = getSourceTypeForVideo(
        data.files.intro_video.url
      )
    }

    // paragraphs
    result.section.paragraphs = getParagraphs(data.blocks)

    return result
  }

  const mainMenu = (data) => {
    const result = {}
    result.menu_items = []

    data.forEach((firstLevelItem) => {
      const firstLevelItemObject = {}

      firstLevelItemObject.id = firstLevelItem.id
      firstLevelItemObject.label = firstLevelItem.label
      firstLevelItemObject.submenu = []

      if (firstLevelItem.items) {
        firstLevelItem.items.forEach((secondLevelItem) => {
          const secondLevelItemObject = {}

          secondLevelItemObject.label = secondLevelItem.label
          secondLevelItemObject.path = secondLevelItem.path
          secondLevelItemObject.pathTranslated = secondLevelItem.pathOriginal
          secondLevelItemObject.endpoint = secondLevelItem.endpoint

          firstLevelItemObject.submenu.push(secondLevelItemObject)
        })
      }

      result.menu_items.push(firstLevelItemObject)
    })

    return result
  }

  return {
    countryPage,
    emailSettings,
    generalInternalNavigation,
    generalPage,
    getTranslations,
    jobPage,
    homePage,
    mainMenu,
    newsPage,
    newsListingPage,
    portfolioSectorPage,
    projectPage,
    sectorPage,
  }
 
}

function formatDateFull(inputDate, language = 'en-US') {
  const options = {year: 'numeric', month: 'long', day: '2-digit'}

  // Check if the input date contains "T" (indicating it's in the extended format)
  const formattedDate = inputDate.includes('T')
    ? inputDate.split('T')[0] // Extract only the date part
    : inputDate

  const date = new Date(formattedDate)
  return date.toLocaleDateString(language, options)

  // const options = {year: 'numeric', month: 'long', day: '2-digit'}
  // const date = new Date(inputDate)
  // return date.toLocaleDateString(language, options)
}

function formatDate(inputDate, language = 'en-US') {
  const options = {year: 'numeric', month: 'long', day: '2-digit'}
  const date = new Date(inputDate)
  return date.toLocaleDateString(language, options)

  // let date

  // // Check if the input date includes a time component
  // if (inputDate.includes('T')) {
  //   // Parse as ISO format with time
  //   date = new Date(inputDate)
  // } else {
  //   // Parse as a simple date string
  //   date = new Date(inputDate.replace(/-/g, '/'))
  // }

  // // Check if the parsed date is valid
  // if (isNaN(date.getTime())) {
  //   // If not valid, return the input as is
  //   return inputDate
  // }

  // const options = {year: 'numeric', month: 'long', day: '2-digit'}
  // return date.toLocaleDateString(language, options)
}

function formatDateNotime(inputDate, language = 'en-US') {
  // const options = {year: 'numeric', month: 'long', day: '2-digit'}
  // const date = new Date(inputDate)
  // return date.toLocaleDateString(language, options)

  const options = {year: 'numeric', month: 'long', day: '2-digit'}
  const [year, month, day] = inputDate.split('-').map(Number)
  const date = new Date(Date.UTC(year, month - 1, day, 0, 0, 0)) // Imposta l'orario a mezzanotte UTC
  return date.toLocaleDateString(language, options)

  // let date

  // // Check if the input date includes a time component
  // if (inputDate.includes('T')) {
  //   // Parse as ISO format with time
  //   date = new Date(inputDate)
  // } else {
  //   // Parse as a simple date string
  //   date = new Date(inputDate.replace(/-/g, '/'))
  // }

  // // Check if the parsed date is valid
  // if (isNaN(date.getTime())) {
  //   // If not valid, return the input as is
  //   return inputDate
  // }

  // const options = {year: 'numeric', month: 'long', day: '2-digit'}
  // return date.toLocaleDateString(language, options)
}

function cleanImageUrl(url) {
  // Split the URL into base and query parts
  const parts = url.split('?')
  if (parts.length !== 2) {
    return url // No query string found
  }

  const baseUrl = parts[0]
  const queryString = parts[1]

  // Parse the query string into key-value pairs
  const params = queryString.split('&').reduce((acc, param) => {
    const [key, value] = param.split('=')
    acc[key] = value
    return acc
  }, {})

  // Remove 'fit' and 'crop' parameters
  delete params['fit']

  // add max size parameter
  params['w'] = '1920'

  // Reconstruct the query string
  const newQueryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&')

  // Reconstruct the URL
  const newUrl = newQueryString ? `${baseUrl}?${newQueryString}` : baseUrl
  return newUrl
}
